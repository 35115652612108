@import '../../styles/variables/colors';
.reference-line-legend-group {
    .reference-line-label {
        font-size: 14px;
        margin-left: -30%;

        &:before {
            display: inline-block;
            content: "-";
        }

        &.target-response-time {
            color: #333333;
        }


        &.all-wards {
            color: #70ACD1;
        }

        &.selected-ward {
            color: $tena-dark-blue;
        }
    }
}
.recharts-surface {
    margin-bottom: 2px;
}

