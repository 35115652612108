@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

.columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    font-family: 'Asap', sans-serif;
    > div {
        flex: 1 1 100%;
    }
    > div:first-child {
        margin-right: 100px;
    }
}

#setup-view {
    .save-button {
        background-color: $tena-dark-blue;
        color: white;
        margin-right: 10px;
        font-weight: normal;
        padding: 10px 20px 10px 20px;
        font-size: $tena-font-size-1;
        font-family: 'Asap', sans-serif;
    }

    .cancel-button {
        background-color: white;
        color: #373737;
        margin-right: 10px;
        padding: 10px 20px 10px 20px;
        font-size: $tena-font-size-1;
        font-family: 'Asap', sans-serif;
    }

    .page-wrapper {
        background-color: white;
        padding: 2rem;
    }

    .add-ward-button-group {
        display: inline-block;

        .add-ward-button{
            vertical-align: bottom;
            text-align: center;
            padding: 0 8px 3px 8px;
            font-size: $tena-font-size-1;
            border: none;
            margin-left: 5px;
            background: $tena-welcome-grey;
            font-family: 'Asap', sans-serif;
        }
    }
    .wards-column-div {
        margin: 0;
    }
    .ward-list {
        border-radius: 2%;
        border: 1px solid lightgrey;
        margin-top: 10px;
        overflow: hidden;
        height: 92%;
        overflow-y: scroll;

        .ward-list-row {
            padding: 1rem 1rem 1rem 0.5rem;
            margin: 0;
            font-size: 16px;
            display: block;

            svg {
                float: left;
                margin: 7px;
            }

            &.active-ward {
                svg {
                    circle {
                        fill: $tena-green;
                    }
                }
            }

            &.disactive-ward {
                svg {
                    circle {
                        fill: $tena-grey-4;
                    }
                }
            }

            &:nth-child(even) {
                background: $tena-grey-background;
            }

            .ward-edit-button-img {
                width: 25px;
            }
        }
    }

    @mixin save-message {
        margin-top: 15px;
        font-size: $tena-font-size-1;
        font-family: 'Asap', sans-serif;
    }

    .save-message-success {
        @include save-message;
    }

    .save-message-failed {
        @include save-message;
        color: red;
    }

    .notifications-column {
        float: left;
    }

    .wards-section {
        height: 100%;
    }

    .fields-box-title {
        color: #333;
        font-weight: 600;
        font-size: 20px;
        font-family: 'Asap', sans-serif;
        margin-bottom: 30px;
    }

    .form-buttons {
        margin-top: 1rem;
    }

    .react-select__option {
        line-height: 1;
        font-weight: normal !important;
        background: white !important;
        font-size: 1rem !important;
        color: black !important;
    }
    .react-select__single-value {
        font-weight: normal;
    }

}
