@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';


.no-border-block {
    border: none;
}

.resident-history.modal{
    .modal-dialog {
        width: 90rem
    }
}

#residents-history-view-info {
    .table-wrapper {
        overflow-y: auto;
        background-color: white;
        margin: 25px 10px 15px 10px;
        height: 500px;
        text-align: left;
        font-size: 20px;
        .fa {
            vertical-align: left;
        }
    }
}

.general-info-child {
    color: $tena-dark-blue;
}

.general-info-child-warning {
    color: $tena-error-red;
}

.information-block-wrapper {
    min-width: 208px;
}

