.react-toggle {
	margin-top: 5px !important;
	touch-action: pan-x;
	
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
	
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
}
	
.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
	
.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}
	
.react-toggle-track {
	width: 55px;
	height: 27px;
	padding: 0;
	border-radius: 30px;
	background-color: #CCCCCC;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.react-toggle--checked .react-toggle-track {
	background-color: #005597;
	color: white;
}
	
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #005597;
	color: white;
}
	
.react-toggle-track-check {
	position: absolute;
	width: 16px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}
	
.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}
	
.react-toggle-track-x {
	position: absolute;
	width: 15px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}
	
.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}
	
.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 25px;
	height: 25px;
	border: 1px solid grey;
	border-radius: 50%;
	background-color: #FAFAFA;
	
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}
	
.react-toggle--checked .react-toggle-thumb {
	left: 29px;
	border-color: #CCCCCC;
}

.on-text-size {
		padding-top: 4.5px;
		font-size: 10px;
		font-weight: 900;
}
	
.off-text-size {
	padding-top: 4.5px;
	font-size: 10px;
	font-style: 900;
}