/* Rectangle */

.rectangle {
    width: 50%;
    height: 17px;
    position: relative;

    .rectangle__item {
        border: 2px solid #E0E0E0;
        display: inline-block;
        border-radius: 20px;
        padding: 6px;
        background-color: white;

        &--active {
            border-color: #1D388B;
        }

        &--disabled {
            border-color: #828282;
        }
    }

    .rectangle__background {
        display: flex;
        flex-direction: row-reverse;
        width: 449px;

        span {
            width: calc(100% / 3);

            &:not(:last-of-type) {
                width: calc(100% / 3 + 10px);
                margin-left: -10px;
            }
        }
    }

    .rectangle__active {
        width: 449px;
        position: absolute;
        display: flex;
        top: 0;
    }
}

.indicatorTexts {
    width: 100%;
    text-transform: uppercase;


    .text {
        font-size: 12px;
        display: inline-block;
        text-align: center;
        width: 135px;
        color: #BDBDBD;
        letter-spacing: 0.5px;
        &.active {
            color: #1D388B;
            font-weight: 600;
            &.disabled {
                color: #828282;
            }
        }
    }
}
