@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

#statistics-view {
    .table-wrapper {
        background-color: white;
        padding: 1rem 0 0;
        text-align: center;
        font-size: 16px;

        .table-header {
            margin-bottom: 15px;
        }

        .day-night-span {
            display: inline-block;
            color: $tena-dark-blue;
            font-weight: bold;

            img {
                width: 30px;
                height: auto;
                margin-right: 1rem;
            }
        }

        .table-load-footer {
            text-align: center;
            div {
                display: inline-block;
                padding: 2rem;
            }
        }
    }

    th {
        width: 20%;
    }

    #history-icon {
        text-align: right;
        padding-right: 20px;
    }

    .aboveThreshold {
        color: $tena-warning-red;
    }

    #header-title {
        text-align: left;
        padding-left: 20px;
    }

    .table-row {
        text-align: left;
    }

    .table-row-data {
        padding-left: 14px;
    }

    .date-row {
        min-width: 250px;
        white-space: nowrap;
    }

    .statistics-page-control {
        background-color: white;
        display: flex;
        justify-content: space-around;
        margin: 25px 10px 15px 10px;
        text-align: center;
        font-size: 20px;

        .fa {
            font-size: 0.6em;
            vertical-align: middle;
        }
    }

    .overtarget-error-icon {
        width: 25px;
        padding-right: 5px;
    }

    .overtarget-error-text {
        font-size: 18px;
        color: $tena-error-red;
        display: inline;
        font-weight: normal;
    }

    .chart-wrapper {
        width: 85%;
    }

    .chart {
        width: 100%;
    }

    .c3 .c3-axis-x path, .c3 .c3-axis-x {
        stroke: grey;
    }

    .c3 .c3-axis-y path, .c3 .c3-axis-y {
        stroke: grey;
    }

    .c3-grid text {
        font-size: 16px;
        fill: grey;
    }

    .c3-ygrid-line line {
        stroke-width: 2px;
    }

    .c3-ygrid-line.dotted-grid line {
        stroke-width: 2px;
        stroke: $tena-dark-blue;
    }

    .c3-ygrid-line.dotted-grid text {
        fill: $tena-dark-blue;
    }

    .c3-ygrid-line.dotted-grid {
        stroke-linecap: round;
        stroke-dasharray: 1, 10;
    }

    .c3-grid text {
        font-size: 16px;
        fill: grey;
    }

    .c3-legend-item text {
        font-size: 16px;
    }

    .c3-axis-y text {
        font-size: 16px;
        font-weight: 200;
    }

    .c3-axis-x text {
        font-size: 16px;
        font-weight: 200;
    }

    .c3-axis-x .tick + .tick text {
        text-anchor: end !important; // override c3js inline style
    }

    .btn-default.view-icon-wrapper:focus {
        background-color: $tena-dark-blue;
    }

    .history-resident-button {
        cursor: pointer;
        border:none;
        padding: 0.4rem;
        background-color:transparent;
        min-width: 4rem;
        span {
            display: block;
            color: $tena-title-grey;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.fa {
                font-size: 24px;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &:active:focus {
            outline: none;
            border-color: transparent;
            background-color: $icon-grey;
            box-shadow: none;
            span {
                color: $tena-title-black;
            }
        }
    }
    
}