@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

#residents-view {
    padding: 2rem;

    .table-wrapper {
        background-color: white;
        padding: 1rem 0 0;
        text-align: center;
        font-size: $tena-font-size-1;
    }

    .table-error-red-color {
        > img {
            width: 15px;
            margin-bottom: 5px;
        }
        color: $tena-error-red;
    }
}

.residents-view-info {
    background-color: white;
    display: flex;
    justify-content: space-around;
    margin: 0 0 2rem;
    text-align: center;
    font-size: 20px;
}

.ok-status {
    color: $tena-green;
}

.down-status, .above_max_changes {
    color: $tena-error-red;
}

.general-info-child {
    color: $tena-dark-blue;
}

.actions-column {
    text-align: left;
    &.residents-table-row {
        text-align: center;
        word-break: break-all;
    }
}

.status-icon-wrapper {
    display: flex;
    align-items: center;
    width: 170px;
    text-align: left;
}
.flex {
    display: inline-flex;
}

.status-icon-img {
    width: 30px;
    height: auto;
}
.status-base-text {
    padding-left: 10px;
    line-height: 110%;
}
.status-Error-text {
    color:$icon-red;
}
.status-Ok-text {
    color:$icon-green;
}
.status-Change-text {
    color:$icon-red;
}
.status-Check-text, .status-Apply-text, .status-Reapply-text {
    color: $icon-orange;
}
.status-Inactive-text {
    color: $icon-grey;
}
.status-NotBeingMonitored-text {
    color: $icon-grey;
}

.resident-table-droplet-group > * {
    width:11px;
    margin: 0 2px 2px 2px;
}

.resident-table-droplet-group {
    display:inline-block;
}

.resident-all-day-icon-img {
    width: 25px;
    height: 25px;
    margin-left: 5px;
}
.resident-name-row {
    display: inline-flex;
}

.header-status-wrapper {
    text-align: left;
    width: 180px;
    padding-right: 5px
}