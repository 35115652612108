@import "../../../../styles/variables/colors.scss";

.rc-picker .rc-picker-time-range .rc-picker-focused{
  border-radius: 0px !important;
  input{
    text-align: center;
  }
}
div.rc-picker.rc-picker-time-range.rc-picker-focused {
  border: 3px solid white !important;
  box-shadow: none !important;
  input {
    cursor: pointer;
    background-color: #CCDAE9 !important; 
  }
}

.rc-picker .rc-picker-time-range {
  .rc-picker-input{
    input{
      text-align: center;
    }
  }
}
.rc-picker-time-range {

    width: 84px;
    display: flex !important;
    border: 1px solid $middle-grey;
    height: 32px;
    border-radius: 0px;
    transition: background-color .5s;
    border-radius: 2px !important;
    background-color: white !important; 
    .rc-picker-input {
      font-size: inherit;
      display: flex;
      color: $almost-black;
      input {
        cursor: pointer !important;
        border: 0px;
        text-align: center !important;
        border-radius: 2px;
        padding: 0 !important;
        color: $almost-black;
        transition: background-color .5s;
        &:focus {
          outline: none;
          border-radius: 0px !important;
        }
      }
    }
}

.rc-picker-panel-container-time-range{
    z-index: 1100;
    box-shadow: none !important;

    .rc-picker-panel {
        background: $full-white !important;
        border: 1px solid $middle-grey !important;
      
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        width: 100%;
      }
    

    .rc-picker-time-panel .rc-picker-content {
        max-height: 248px !important;
      }
      
      .rc-picker-panel-container {
        margin-top: 0px;
      }
      
      .rc-picker-time-panel-cell-inner {
        color: inherit !important;
        font-size: 16px;
        height: 40px !important;
        line-height: 40px !important;
        text-align: center !important;
        padding: initial !important;
      }

      .rc-picker-time-panel-cell {
        color: $almost-black;
      }
      
      .rc-picker-time-panel-cell .rc-picker-time-panel-cell-disabled {
        color: #ccc !important;
      }
      
      .rc-picker-time-panel-cell-disabled { 
        color: $solid-grey !important;
      }
      
      .rc-picker-time-panel-cell-disabled:hover { 
        background: $full-white !important;
      }
      
      .rc-picker-time-panel-cell:hover {
        background: $dropdown-hover;
        &:active{
          background: $dropdown-mousedown;
          color: $almost-black;
        }
      }
      
      .rc-picker-time-panel-cell-selected {
        background: $dropdown-active;
        color: $full-white;
        :hover{
          background: $dropdown-active;
          color: $full-white;
        }
      }
      
      .rc-picker-time-panel-column > li .rc-picker-time-panel-cell-inner {
        font-size: 16px;
        height: 24px;
        line-height: 24px;
      }
      
      .rc-picker-time-panel-column {
        padding: 0 0 0 0 !important;
        overflow-y: visible !important;        
        &:first-child {
          width: calc(50% + 8px);
        }
        &:last-child {
          width: calc(50% - 8px);
        }
      }
      
      .rc-picker-dropdown-placement-bottomLeft {
        z-index: 1100;
      }

}
