@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

.edit-ward.modal{
    .modal-dialog {
        width: 40rem
    }
}



.ward-checkbox {
    display: flex;
    flex-direction: row;
    color:red;
}

.add-ward-modal-body, .edit-ward-modal-body {
    padding: 20px 20px 30px;
}

.ward-input-field {
    font-size: $tena-font-size-1;
}

.err-ward-input-field {
    font-size: $tena-font-size-1;
    color: red;
}

.add-edit-ward-form-input-field {
    width: 50%;
    color:$tena-form-black;
}

.err-add-edit-ward-form-input-field {
    border-color: RED;
    background: $invalid-field-background-color;
    width: 50%;
}
