@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

.resident-edit-button-img {
    width:25px;
}

.edit-resident-button {
    cursor: pointer;
    border:none;
    padding: 0.4rem;
    background-color:transparent;
    min-width: 4rem;
    span {
        display: block;
        color: $tena-title-grey;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.fa {
            font-size: 24px;
        }
    }
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        outline: none;
        border-color: transparent;
        background-color: $icon-grey;
        box-shadow: none;
        span {
            color: $tena-title-black;
        }
    }
}


.edit-resident-button-wrapper {
    display:inline-block;
}

.edit-resident-button-wrapper:focus {
    outline:none;
}

.edit-resident.modal{
    .modal-dialog {
        width: 90rem
    }
}

.resident-history-view-info,
.resident-history-all-day-view-info {
    background-color: white;
    display: flex;
    justify-content: space-around;
    margin: 25px 10px 15px 10px;
    text-align: center;
    font-size: $tena-font-size-2;
}

.general-info-child {
    color: $tena-dark-blue;
}

.confirm-delete-text {
    font-size: $tena-font-size-4;
}

.add-edit-button-group {
    margin-top: 20px;
    float: right;
    & > :not(:last-child) {
        margin-right: 10px;
    }
}
