@import '../../styles/variables/fonts';
@import '../../styles/variables/colors';

.form-group-template {
    // all fonts are same in the form group
    label,
    input
    {
        font-size: 16px;
    }
    button {
        font-size: 16px;
    }

    label {
        display: inline;
    }

    input,
    .dropdown,
    button {
        padding: 1.25rem 0.5rem;
    }

    .form-group-label {
        font-weight: normal;
        font-size: 16px;
    }

    &.status-checkbox {
        margin-top: 10px;
    }

    // checkbox customization
    label input[type="checkbox"] + span.scaCheckbox div.scaCheckbox {
        position: absolute;
        top: 0;
        width: 30px;
        height: 20px;
        margin-top: 0;
        margin-left: 0;
    }

    label {
        input[type="checkbox"] {
            display: none;
        }
        input[type="checkbox"] + span.scaCheckbox {
            display: inline-block;
            width: 37px;
            height: 37px;
            margin: 0 10px -3px -20px;
            border: solid 1px #ccc;
            -webkit-border-radius: 4px;
            border-radius: 4px;
            div.scaCheck {
                height: 22px;
                width: 22px;
                display: inline-block;
                margin-left: 6px;
                margin-top: 6px;
            }
        }
        input[type="checkbox"]:checked + span.scaCheckbox {
            span {
                color: #757575;
            }
        }
        input[type="checkbox"]:checked + span.scaCheckbox {
            div.scaCheck {
                background: url('../../images/checkmark.gif');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
    margin-bottom: 1rem;

}

.form-group-error {
    label {
        color: #D8574C;
    }

    .dropdown {
        .dropdown-toggle {
            border: 1px solid #D8574C;
            background-color: #FBF2F2;
        }
    }
}



