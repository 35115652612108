@import '../../styles/variables/colors';

.information-block {
    background-color: white;
    padding: 10px 15px;
    text-align: center;
    line-height: 1.8;
    border-left: thin solid $tena-grey-background;

    > div {
        font-size: 20px;
        font-weight: bold;
    }
}
