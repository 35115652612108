.modal-header {
    font-size: 20px;
    background: #edeff0;
    padding: 10px 15px;
    display: flex !important;
    .close {
        padding: 0 1rem 1rem;
        font-size: 45px;
        outline: none;
        margin-top: -12px;
    }
}
