@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

.statistics-view-info {
    background-color: white;
    margin: 0 0 2rem;
    padding: 0 0 1rem;
    text-align: left;

    button:not(.react-datepicker__navigation) {
        min-width: 240px;
    }

    .btn-group {
        width: auto;
    }
}

.general-info-child {
    color: $tena-dark-blue;
    max-width: 240px;
}

.blue-icon-background {
    background-color: $tena-dark-blue;
    min-width: 65px;
}

#view-selection-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
}

.view-icon-img {
    margin: 10px 0;
    width: 20px;
    height: 20px;
}
.view-icon-wrapper {
    width: 65px;
    padding: 0;
}

.export-icon-wrapper {
    width: 65px;
    height: 50px;
}

.view-icon-wrapper:active:focus,
.view-icon-wrapper:active,
.view-icon-wrapper:target,
.view-icon-wrapper:focus {
    outline:none;
    box-shadow:none;
}

#position-view-picker {
    display: flex;
    justify-content: center;
}
