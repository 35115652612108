@import '../styles/variables/colors';
@import '../styles/variables/fonts';

.help-popup.modal{
    .modal-dialog {
        width: 40rem;
        .help-modal-body {
            padding: 20px 20px 30px;
            font-size: 16px;
        }
    }
}
