/* TENA web colour palette */
/* FROM: TENA Professional Care Communications Guidelines */
$tena-green: #85ba35; // used for directive buttons and links
$tena-blue: #00488F; // used for standard text

// used to add subtle contrast where needed
$tena-grey-1: #f9f6f2;
$tena-grey-2: #e6e6e6;
$tena-grey-3: #edf4f3;
$tena-grey-4: #e1e8e7;
$tena-grey-background: #FFFFFF;

// used for call to action bars
$tena-dark-blue: rgb(0, 59, 115);
/* end TENA web colour palette */

$tena-title-black: #343434;

$tena-form-black: #2F2F2F;

$tena-title-grey: #6c7780;

$tena-welcome-grey: #848d95;

$tena-error-red: #ac2925;

$tena-warning-red: #D23147;

$tena-popover: #e5edf4;

$invalid-field-background-color: #fdf2f2;

// used for status text to match status icon colour
$icon-orange: #e37f1b;
$icon-green: #80a843;
$icon-red: #cc2b3e;
$icon-grey: #bcbcbc;

$full-white: #ffffff;

// new MARY UI color:

$dropdown-hover: #E5ECF4;
$dropdown-mousedown: #D1D8E0;
$dropdown-active: #00488F;

$middle-grey: #BDBDBD;
$solid-grey: #828282;

$almost-white: #EDEFF0;
$almost-black: #333;
$white-hover: #F8F9FA;
