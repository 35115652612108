@import './variables/colors';

table {
    thead {
        tr {
            th {
                font-weight: normal;
                border-bottom: 0;
                text-align: center;
            }
        }
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: $tena-grey-background;
    }

}

.dropdown.open {
    >.btn-default.dropdown-toggle,
    >.btn-default.dropdown-toggle:focus {
        background-color: white;
    }
}

.dropdown {
    &.show {
        & > .btn.dropdown-toggle {
            background-color: $white-hover;
        }
    }

    .btn:not(:disabled):not(.disabled):active,
    .btn:not(:disabled):not(.disabled):focus {
        background-color: $white-hover;
    }

    .dropdown-toggle {
        color: #333;

        &:hover {
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            background-color: $full-white;
        }

        &:after {
            border: {
                top: 6px solid;
                right: 5px solid transparent;
                bottom: 0;
                left: 5px solid transparent;
            }
        }

        &:disabled {
            background-color: $almost-white;
            border: 1px solid $middle-grey;
            color: $solid-grey;
            box-shadow: none;
            opacity: 1;
            cursor: auto;
        }
    }

    .dropdown-item,
    .container {
        &:not(.active) {
            &:hover {
                background-color: $dropdown-hover;

                &:active {
                    background-color: $dropdown-mousedown;
                    color: $almost-black;
                }
            }
        }
    }

    .dropdown-menu {
        width: 100%;
    }

    img {
        max-width: 21px;
        margin-top: -2px;
    }

    .react-select__control {
        color: $almost-black;
        flex-wrap: nowrap;
        padding: 6px 4px 6px 12px;
        min-height: 0;
        height: 42px;
        cursor: pointer;
        border: 1px solid $middle-grey;
        &--menu-is-open {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            background-color: $white-hover;
            &:hover {
                background-color: $white-hover;
                box-shadow: none !important;
            }
        }

        &:hover {
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .react-select__menu {
        margin-top: -1px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        overflow: auto;
        border: 1px solid $middle-grey;
        box-shadow: none;
        padding: 8px 0px 8px 0px;
    }

    .react-select__dropdown-indicator {
        svg {
            display: none;
        }

        &:after {
            content: "";
            color: $almost-black;
            border: {
                top: 6px solid;
                right: 5px solid transparent;
                bottom: 0;
                left: 5px solid transparent;
            }
        }
    }
}

.react-select__menu-portal {
    .react-select__menu {
        margin-top: -1px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        overflow: auto;
        border: 1px solid $middle-grey;
        box-shadow: none;
        padding: 8px 0px 8px 0px;
        max-height: inherit;
    }
}

.btn-light {
    border-color: $tena-blue;
}
