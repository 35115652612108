@import "../../../../styles/variables/colors.scss";

.rc-picker-panel {
    background: $full-white !important;
    border: 1px solid $middle-grey !important;
  
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
  }
  .rc-picker-focused {
    border: 1px solid $middle-grey !important;
    box-shadow: 0 1px 5px #ccc !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .rc-picker-disabled { 
      color: $solid-grey !important;
      background: $almost-white !important;
      border-color: $middle-grey !important;
      cursor: auto !important;
      input {
        color: $solid-grey !important;
      }
  }
  .rc-picker {
    display: flex !important;
    border: 1px solid $middle-grey;
    height: 42px;
    border-radius: 4px;
    transition: background-color .5s;
  
    .rc-picker-focused {
      border: 1px solid $middle-grey !important;
    }
    .rc-picker-input {
      font-size: inherit;
      display: flex;
      color: $almost-black;
      input {
        border: 0px;
        border-radius: 4px;
        padding: 0.375rem 0.75rem;
        color: $almost-black;
        transition: background-color .5s;
        &:focus {
          outline: none;
        }
      }
    }
  }
  
  .rc-picker-time-panel .rc-picker-content {
    max-height: 248px !important;
  }
  
  .rc-picker-panel-container {
    margin-top: -6px;
    width:100%;
  }
  
  .rc-picker-footer {
    display: none;
  }
  
  .rc-picker-dropdown {
    box-shadow: none !important;
  }
  
  .rc-picker-time-panel-cell-inner {
    color: inherit !important;
    font-size: 16px;
    height: 40px !important;
    line-height: 40px !important;
    text-align: center !important;
    padding: initial !important;
  }
  
  .rc-picker-time-panel-cell {
    color: $almost-black;
  }

  .rc-picker-time-panel-cell .rc-picker-time-panel-cell-disabled {
    color: #ccc !important;
  }
  
  .rc-picker-time-panel-cell-disabled { 
    color: $solid-grey !important;
  }
  
  .rc-picker-time-panel-cell-disabled:hover { 
    background: $full-white !important;
  }
  
  .rc-picker-time-panel-cell:hover {
    background: $dropdown-hover;
    &:active{
      background: $dropdown-mousedown;
      color: $almost-black;
    }
  }
  
  .rc-picker-time-panel-cell-selected {
    background: $dropdown-active;
    color: $full-white;
    :hover{
      background: $dropdown-active;
      color: $full-white;
    }
  }
  .rc-picker-time-panel-column > li .rc-picker-time-panel-cell-inner {
    font-size: 16px;
    height: 24px;
    line-height: 24px;
  }
  
  .rc-picker-time-panel-column {
    padding: 0 0 0 0 !important;
    overflow-y: visible !important;        
    &:first-child {
      width: calc(50% + 8px);
    }
    &:last-child {
      width: calc(50% - 8px);
    }
  }
  
  .rc-picker-dropdown-placement-bottomLeft {
    z-index: 1100;
  }
  
  .rc-picker-suffix {
      padding-top: 9px;
      padding-right: 9px;
  }