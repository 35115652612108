@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

.table-wrapper {
    position: relative;

    .resident-history-table{
        width: 100%;

        .history-header {
            text-align: left;
        }

        th:first-of-type {
            width: 240px;
        }

        th:nth-of-type(2) {
            width: 240px;
        }
    }

    .icon-position-history{
        color: #003b73;
        margin-top: 7px;
        margin-left: 7px;
    }

    .table-load {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(255, 255, 255, 0.75);

        div {
            display: inline-block;
            padding: 2rem;
        }
    }
}

.history-resident-button-wrapper {
    display:inline-block;
    background-color:transparent;

    .history-resident-button {
        cursor: pointer;
        border:none;
        padding: 0.4rem;
        background-color:transparent;
        min-width: 4rem;
        span {
            display: block;
            color: $tena-title-grey;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.fa {
                font-size: 24px;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &:active:focus {
            outline: none;
            border-color: transparent;
            background-color: $icon-grey;
            box-shadow: none;
            span {
                color: $tena-title-black;
            }
        }
    }
}

.general-info-child {
    color: $tena-dark-blue;
}
